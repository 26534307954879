import { LegalAgent, LegalEntityTypeEnum } from '@/types';

export enum FormFields {
  Avatar = 'avatar',
  BirthDate = 'birthDate',
  City = 'city',
  Discount = 'discount',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  INN = 'inn',
  IsEmployee = 'isEmployee',
  LegalEntityType = 'legalEntityType',
  Email = 'email',
  OfficialAddress = 'officialAddress',
  MailAddress = 'mailAddress',
  Password = 'password',
  Phone = 'phone',
  Representative = 'representative',
  Role = 'role',
  Sex = 'sex',
  SocialMedia = 'socialMedia',
  SubscribeForNews = 'subscribeForNews',
  Title = 'title',
  Website = 'website',
}

export const legalAgentFormInitialValues:Partial<LegalAgent> = {
  email: '',
  inn: '',
  phone: '',
  socialMedia: [],
  discount: 0,
  representative: [],
};

export const legalEntityTypes: Record<LegalEntityTypeEnum, string> = {
  [LegalEntityTypeEnum.JointStockCompanies]: 'Акционерные общества (АО)',
  [LegalEntityTypeEnum.LimitedLiabilityCompanies]: 'Общества с ограниченной ответственностью (ООО)',
};
