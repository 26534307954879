import cn from 'classnames';
import { v4 } from 'uuid';

import styles from './styles.module.scss';
import type { InputProps } from './types';
import { forwardRef } from 'react';

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ id = v4(), error, endIcon: EndIcon, label, light, variant, labelClassName, ...props }: InputProps, ref) => {
    return (
      <label className={cn(styles.label, labelClassName)} htmlFor={id}>
        {Boolean(label) && label}
        <div className={styles.wrapper}>
          <input
            id={id}
            className={cn(
              styles.input,
              {
                [styles.inputInvalid]: Boolean(error),
                [styles.inputSmall]: variant === 'small',
                [styles.inputEmpty]: variant === 'empty',
                [styles.inputLight]: light,
                [styles.inputEndIcon]: Boolean(EndIcon),
                [styles.inputSmaller]: variant === 'smaller',
              },
              props.className,
            )}
            onChange={props.onChange}
            ref={ref}
            {...props}
          />
          {EndIcon && (
            <div className={styles.endIcon}>
              <EndIcon />
            </div>
          )}
        </div>

        {error && <p className={styles.error}>{error}</p>}
      </label>
    );
  },
);

export default Input;
