import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import cn from 'classnames';

import OrderBoard from '../OrderBoard';
import OrderTableList from '../OrderTableList';

import styles from './styles.module.scss';

import Header from '@/components/Header';
import IconAdd from '@/components/Icons/IconAdd';
import IconKanban from '@/components/Icons/IconKanban';
import IconTable from '@/components/Icons/IconTable';
import Meta from '@/components/Meta';
import Button from '@/components/UI/Button';
import IconButton from '@/components/UI/IconButton';
import { OrdersView, VIEW_PARAM } from '@/features/Order/components/OrderView/constants';
import { createOrder } from '@/features/Order/services';

const OrderView = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [isFetching, setIsFetching] = useState(false);

  const isTableView = !params.get(VIEW_PARAM) || params.get(VIEW_PARAM) === OrdersView.Table;

  const toggleView = () => {
    setParams(() => ({
      ...params,
      view: isTableView ? OrdersView.Board : OrdersView.Table,
    }));
  };

  const view = useMemo(() => params.get(VIEW_PARAM) || OrdersView.Table, [params.get(VIEW_PARAM)]);

  const handleCreateOrder = async () => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      const { _id } = await createOrder({});
      navigate(`/orders/${_id}`);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={cn(styles.container, isTableView && styles.containerTable)}>
      <Meta title="Сделки" />
      <Header
        title="Сделки"
        showSearch={false}
        actions={
          <Button disabled={isFetching} isLoading={isFetching} onClick={handleCreateOrder} endIcon={IconAdd}>
            Сделка
          </Button>
        }
        filters={
          <>
            <IconButton icon={isTableView ? IconKanban : IconTable} onClick={toggleView} />
          </>
        }
      />
      {view === OrdersView.Board && <OrderBoard />}
      {view === OrdersView.Table && <OrderTableList />}
    </div>
  );
};

export default OrderView;
