import cn from 'classnames';

import styles from './styles.module.scss';
import type { IconButtonProps } from './types';

import IconLoading from '@/components/Icons/IconLoading';
import { forwardRef } from 'react';

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      icon: Icon,
      disabled,
      isLoading,
      label,
      onClick,
      size = 'md',
      iconSize,
      type = 'button',
      variant = 'empty',
    }: IconButtonProps,
    ref,
  ) => (
    <button
      ref={ref}
      aria-label={label}
      className={cn(
        styles.button,
        styles[`button--${variant}`],
        styles[`button--size-${size}`],
        iconSize && `[&>svg]:w-[${iconSize}px] ![&>svg]:h-[${iconSize}px]`,
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {isLoading ? <IconLoading /> : <Icon />}
    </button>
  ),
);

export default IconButton;
