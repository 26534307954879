import type { CounterpartAccountEntity } from '@/features/Order/types';
import type { IndividualAgent, LegalAgent, UserType } from '@/types';

export const getAgentFullName = (
  agent: IndividualAgent | LegalAgent | UserType | CounterpartAccountEntity,
  useMiddleName = true,
) => {
  if (!agent) {
    return '';
  }
  const { lastName, firstName, middleName, email } = agent;
  const data = [lastName, firstName];

  if (useMiddleName) {
    data.push(middleName);
  }
  if (!data.filter(Boolean).length) {
    const reseveData = [];
    if ('phone' in agent && agent.phone) {
      reseveData.push(agent.phone);
    }
    if (email) {
      reseveData.push(email);
    }
    if ('title' in agent && agent.title) {
      reseveData.push(agent.title);
    }
    if ('officialAddress' in agent && agent.officialAddress) {
      reseveData.push(agent.officialAddress);
    }
    if ('website' in agent && agent.website) {
      reseveData.push(agent.website);
    }
    if (reseveData.length) {
      data.push(reseveData[0]);
    }
  }
  return data.filter(Boolean).join(' ');
};
