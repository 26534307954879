import { forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { InputDateProps } from './types';

import Input from '@/components/UI/Input';

const InputNumber = forwardRef(
  (
    {
      error,
      label,
      format = '##.##.####',
      name,
      onChange,
      placeholder,
      value,
      errorMessageId,
      descriptionId,
      ...props
    }: InputDateProps,
    ref,
  ) => (
    <div className={styles.container}>
      {Boolean(label) && label}
      <PatternFormat
        {...props}
        name={name}
        getInputRef={ref}
        format={format}
        className={cn(styles.input, error && styles.invalid)}
        placeholder={placeholder}
        onChange={onChange}
        customInput={Input}
        inputMode="tel"
        value={value}
      />
      {Boolean(error) && <p className={styles.error}>{error}</p>}
    </div>
  ),
);

export default InputNumber;
