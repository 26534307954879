import { useEffect, useState } from 'react';
import styles from './../../styles.module.scss';
import IconArrowDown from '@/components/Icons/IconArrowDown';
import IconArrowSubDown from '@/components/Icons/IconArrowSubDown';
import IconArrowUp from '@/components/Icons/IconArrowUp';
import IconCheck from '@/components/Icons/IconCheck';
import IconMinus from '@/components/Icons/IconMinus';
import IconPlus from '@/components/Icons/IconPlus';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import Table from '@/components/UI/Table';
import type { GroupProductEntity, ProductEntity } from '@/features/Product/types';
import Input from '@/components/UI/Input';
import IconEdit from '@/components/Icons/IconEdit';

const ProductAccordion = ({
  index,
  isFetching,
  handleUpdateProductAmount,
  handleUpdateProductItemBarcode,
  onSave,
  product,
}: {
  index: number;
  isFetching: boolean;
  handleUpdateProductAmount: (index: number, value: number) => void;
  handleUpdateProductItemBarcode: (index: number, productItemIndex: number, value: string) => void;
  onSave: () => void;
  product: GroupProductEntity;
}) => {
  const [quantity, setQuantity] = useState(product?.count || 0);
  const [opened, setOpened] = useState(false);
  const [isEditProductsBarcode, setIsEditProductsBarcode] = useState(product?.items?.map((item) => false) ?? []);
  const [barcodes, setBarcodes] = useState(product?.items?.map((item) => item.barcode || '0') ?? []);

  const toggleOpened = () => {
    setOpened((prev) => !prev);
  };

  const handleEditBarcode = (itemIndex: number) => {
    setIsEditProductsBarcode((prev) =>
      prev.map((item, ii) => {
        if (itemIndex === ii) return !item;
        return item;
      }),
    );
  };
  useEffect(() => {
    const productItemsLength = product?.items?.length ?? 0 ;
      setIsEditProductsBarcode((prev) => {
      if (productItemsLength > prev.length) {
        return [...prev, ...new Array(productItemsLength - prev.length).fill(false)];
      }
      if (productItemsLength ?? 0 < prev.length) {
        return prev.slice(0, productItemsLength);
      }
      return prev;
    });

    setBarcodes((prev) => {
      if (productItemsLength > prev.length) {
        return [...prev, ...new Array(productItemsLength - prev.length).fill("0")];
      }
      if (productItemsLength < prev.length) {
        return prev.slice(0, productItemsLength);
      }
      return prev;
    });
  }, [product.items]);

  const handleChange = (itemIndex: number, value: string) => {
    setBarcodes((prev) =>
      prev.map((item, ii) => {
        if (itemIndex === ii) return value;
        return item;
      }),
    );
  };

  return (
    <>
      <Table.Row className={styles.tableRow}>
        <Table.Cell>{product?.title}</Table.Cell>
        <Table.Cell>{product?.brand}</Table.Cell>
        <Table.Cell>{product?.article}</Table.Cell>
        <Table.Cell>
          <Flex alignItems="center">
            <IconButton
              disabled={quantity < 2}
              icon={IconMinus}
              onClick={() => {
                setQuantity(quantity - 1);
                handleUpdateProductAmount(index, quantity - 1);
              }}
              size="xs"
              className="rounded-lg"
              variant="secondary"
            />
            {quantity}
            <IconButton
              icon={IconPlus}
              onClick={() => {
                setQuantity(quantity + 1);
                handleUpdateProductAmount(index, quantity + 1);
              }}
              size="xs"
              className="rounded-lg"
              variant="secondary"
            />
          </Flex>
        </Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell className="!pr-3">
          <Flex alignItems="center" justifyContent="space-between" className="w-full">
            {(product?.amount || 1) * (product?.buyingPrice || 0)} ₽
            <Flex alignItems="center" columnGap="xxs">
              <IconButton icon={opened ? IconArrowUp : IconArrowDown} onClick={toggleOpened} />
              <IconButton
                disabled={isFetching || product?.count === quantity}
                icon={IconCheck}
                onClick={onSave}
                variant="primary"
                size="sm"
              />
            </Flex>
          </Flex>
        </Table.Cell>
      </Table.Row>
      {opened && (
        <>
          {product?.items?.map((item, pi) => (
            <Table.Row className={styles.tableRow} key={`barcode-${pi}`}>
              <Table.Cell colSpan={6}>
                <Flex justifyContent="space-between" fullWidth>
                  <Flex className="py-3" columnGap="xxs">
                    <IconArrowSubDown fill="#7C7A85" size={20} />
                    <Flex>
                      <Input
                        onChange={(e) => {
                          handleChange(pi, e.currentTarget.value);
                        }}
                        value={barcodes[pi]}
                        label="Штрихкод"
                        placeholder="Введите штрихкод"
                        className="text-[12px] h-auto"
                        disabled={!isEditProductsBarcode[pi]}
                        labelClassName="text-[10px] text-black"
                      />
                    </Flex>
                  </Flex>

                  {!isEditProductsBarcode[pi] ? (
                    <IconButton
                      icon={IconEdit}
                      onClick={() => {
                        handleEditBarcode(pi);
                      }}
                      variant="empty-dark"
                      size="sm"
                    />
                  ) : (
                    <Button
                      className="size-fit my-auto"
                      onClick={() => {
                        handleUpdateProductItemBarcode(index, pi, barcodes[pi]);
                        handleEditBarcode(pi);
                        console.log('change');
                      }}
                    >
                      Сохранить
                    </Button>
                  )}
                </Flex>
              </Table.Cell>
            </Table.Row>
          ))}
        </>
      )}
    </>
  );
};

export default ProductAccordion;
