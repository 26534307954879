import { useState } from 'react';

import { isEmpty, map } from 'lodash';

import styles from './styles.module.scss';
import type { WarehouseProductStockModalProps } from './types';

import IconLoading from '@/components/Icons/IconLoading';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import Flex from '@/components/UI/Flex';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Table from '@/components/UI/Table';
import { getProducts } from '@/features/Product/services';
import type { ProductEntity } from '@/features/Product/types';
import { useOnce } from '@/hooks';

const WarehouseProductModal = ({ isOpen, onClose, onSuccess }: WarehouseProductStockModalProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching] = useState(false);
  const [products, setProducts] = useState<ProductEntity[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ProductEntity[]>([]);

  useOnce(() => {
    getProducts({
      pageSize: 100,
      pageNumber: 1,
    })
      .then((res) => {
        setProducts(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const handleSubmit = async () => {
    onSuccess(selectedProducts);
    onClose?.();
  };

  const handleToggleProduct = (product: ProductEntity) => {
    const hasProduct = selectedProducts.find((productItem) => productItem._id === product._id);

    if (hasProduct) {
      setSelectedProducts((prev) => prev.filter((productItem) => productItem._id !== product._id));
    } else {
      setSelectedProducts((prev) => prev.concat(product));
    }
  };

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClose={onClose}>
        <h2>Из номенклатуры</h2>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <IconLoading />
        ) : (
          <Table
            header={
              <Table.Row>
                <Table.Cell>Наименование</Table.Cell>
                <Table.Cell>Бренд</Table.Cell>
                <Table.Cell>Артикул</Table.Cell>
                <Table.Cell>Объем/Мин.шаг реализаций</Table.Cell>
                <Table.Cell>Зарезервированно</Table.Cell>
                <Table.Cell>Цена продажи</Table.Cell>
                <Table.Cell>Мин.остаток</Table.Cell>
                <Table.Cell>Склад</Table.Cell>
                <Table.Cell>Адрес cклада</Table.Cell>
                <Table.Cell>Цена закупки</Table.Cell>
              </Table.Row>
            }
            isEmpty={!products.length}
            isFetching={isLoading}
          >
            {map(products, (product, index) => (
              <Table.Row key={`WarehouseProductModal-${index}`}>
                <Table.Cell>
                  <Flex alignItems="center">
                    <Checkbox onClick={() => handleToggleProduct(product)} />
                    {product?.title}
                  </Flex>
                </Table.Cell>
                <Table.Cell>{product?.brand}</Table.Cell>
                <Table.Cell>{product?.article}</Table.Cell>
                <Table.Cell>{}</Table.Cell>
                <Table.Cell>{}</Table.Cell>
                <Table.Cell>{}</Table.Cell>
                <Table.Cell>{}</Table.Cell>
              </Table.Row>
            ))}
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Flex alignItems="center">
          <Button
            disabled={isEmpty(products) || isEmpty(selectedProducts) || isFetching}
            isLoading={isFetching}
            onClick={handleSubmit}
          >
            Добавить
          </Button>
          <Button onClick={onClose} variant="secondary">
            Отмена
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default WarehouseProductModal;
