import type { Dayjs } from 'dayjs';

import dayjs from '@/utils/dayjs';

export enum CalendarRange {
  Day = 'day',
  Month = 'month',
  Week = 'week',
}
const MIN_IN_MS = 60 * 1000;
const HOUR_IN_MS = 60 * MIN_IN_MS;
const DAY_IN_MS = 24 * HOUR_IN_MS;
const WEEK_IN_MS = 7 * DAY_IN_MS;

export const calendarRanges = [
  { label: CalendarRange.Day, value: 'День' },
  { label: CalendarRange.Week, value: 'Неделя' },
  { label: CalendarRange.Month, value: 'Месяц' },
];

export const getRangeValues = (
  range: CalendarRange,
  startDate = dayjs(),
  endDate = dayjs(),
): { format: string; label: string; endDate?: Dayjs; values: Dayjs[] } => {
  switch (range) {
    case CalendarRange.Month: {
      startDate = startDate.startOf('month');
      endDate = startDate.endOf('month');
      const daysInMonth = startDate.daysInMonth();

      const values = [];

      for (let i = 0; i < daysInMonth; i++) {
        values.push(startDate.add(i, 'day'));
      }

      return {
        format: 'DD.MM',
        label: 'Дата',
        endDate,
        values,
      };
    }

    case CalendarRange.Week: {
      startDate = startDate.startOf('week');
      endDate = startDate.endOf('week');

      const values = [];

      for (let i = 0; i < 7; i++) {
        values.push(startDate.add(i, 'day'));
      }

      return {
        format: 'DD.MM dddd',
        label: 'Дата',
        endDate,
        values,
      };
    }

    default: {
      startDate = startDate.set('hour', 9).set('minutes', 0).set('seconds', 0);
      endDate = startDate.endOf('day').set('hour', 18).set('minutes', 0).set('seconds', 0);

      const values = [];

      for (let i = startDate; i.isBefore(endDate); i = i.add(1, 'hour')) {
        values.push(i);
      }

      return {
        format: 'HH:mm',
        label: 'Время',
        endDate,
        values,
      };
    }
  }
};

export const getShiftDates = (
  range: CalendarRange.Day | CalendarRange.Week | 'hour' | 'minute',
  startDate: Dayjs | string | Date,
  shift: number,
): string => {
  // так как dayjs не поддерживает дробные сдвиги, то используем мс
  let rate = 1;
  switch (range) {
    case 'minute':
      rate = MIN_IN_MS;
      break;
    case 'hour':
      rate = HOUR_IN_MS;
      break;
    case CalendarRange.Day:
      rate = DAY_IN_MS;
      break;
    case CalendarRange.Week:
      rate = WEEK_IN_MS;
      break;
  }
  if (shift >= 0)
    return dayjs(startDate)
      .add(shift * rate, 'millisecond')
      .toISOString();
  else
    return dayjs(startDate)
      .subtract(Math.abs(shift * rate), 'millisecond')
      .toISOString();
};
