import { useParams } from 'react-router';

import Header from './components/Header';
import styles from './styles.module.scss';

import { PATHS } from '@/constants';
import { AgentForm } from '@/features/Agent';
import { AgentTypeToCounterpartType, CounterpartTypeEnum } from '@/types';
import { AgentType } from '@/features/Agent/constants';

const CreateAgent = () => {
  const { agentType } = useParams<{
    agentType: AgentType;
  }>();
  //костыль, потому что происходит какая-то дичь с агентами и контрагентами
  const _agentType = AgentTypeToCounterpartType[agentType ?? AgentType.Individual] ?? agentType;

  return (
    <div className={styles.content}>
      <Header
        breadcrumbs={[{ label: 'Контрагенты', path: PATHS.AGENTS }, { label: 'Новый контрагент' }]}
        title="Добавить контрагента"
      />
      <AgentForm agentType={_agentType} />
    </div>
  );
};

export default CreateAgent;
