import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { isEmpty, isString } from 'lodash';

import { CalendarMarkers } from './components/CalendarMarkers';
import { FormFields, initialValues } from './constants';
import styles from './styles.module.scss';
import type { CalendarFormModalProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import LoadingIndicatorPage from '@/components/LoadingIndicatorPage';
import Button from '@/components/UI/Button';
import DatePicker from '@/components/UI/DatePicker';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Select from '@/components/UI/Select';
import Tabs from '@/components/UI/Tabs';
import Textarea from '@/components/UI/Textarea';
import { EVENTS } from '@/constants';
import { calendarTabs } from '@/features/Calendar/components/CalendarFormModal/constants';
import { fetchCreateCalendarEntry, fetchUpdateCalendarEntry } from '@/features/Calendar/services';
import type { CalendarEntity } from '@/features/Calendar/types';
import { CalendarEntryTypeEnum } from '@/features/Calendar/types';
import { fetchCounterpartAccount } from '@/features/Car/services';
import { fetchOrdersByClient } from '@/features/Order/services';
import type { OrderEntity, ServiceBay } from '@/features/Order/types';
import { useOnce } from '@/hooks';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import type { CounterpartAccount, UserType } from '@/types';
import { getBaseDate } from '@/utils/date';
import dayjs from '@/utils/dayjs';
import { triggerEvent } from '@/utils/event';

export const CalendarFormModal = ({
  date,
  isOpen,
  onClose,
  calendarGroup = [],
  item,
  serviceBay,
}: CalendarFormModalProps) => {
  const [calendarEntity, setCalendarEntity] = useState<CalendarEntity>(
    // @ts-ignore
    () => initialValues,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [carServiceBay, setCarServiceBay] = useState<CounterpartAccount[]>([]);
  const [orders, setOrders] = useState<OrderEntity[]>([]);
  const [, setOrdersByClient] = useState<string>();

  const isEdit = Boolean(item);

  const getTimeRange = (date: string) => {
    let startDay = dayjs(date).startOf('day');
    const endDay = dayjs(date).endOf('day');

    const range = [];

    while (startDay.toDate() < endDay.toDate()) {
      range.push({
        label: startDay.format('HH:mm'),
        value: startDay.toDate(),
      });
      startDay = startDay.add(15, 'minutes');
    }

    return range;
  };

  useEffect(() => {
    if (date) {
      // @ts-ignore
      setCalendarEntity((prev) => ({
        ...prev,
        startAt: date.toDate(),
        endAt: date.add(1, 'hour').toDate(),
      }));
    }

    if (serviceBay) {
      // @ts-ignore
      setCalendarEntity((prev) => ({
        ...prev,
        serviceBay: serviceBay?._id,
      }));
    }

    if (item) {
      // @ts-ignore
      setCalendarEntity(item);
      setOrdersByClient((item?.serviceBay as ServiceBay)?._id);
    }
  }, [item]);

  useOnce(() => {
    Promise.all([fetchCounterpartAccount()])
      .then(([car]) => {
        setCarServiceBay(car?.items);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const handleChangeClient = async (clientId: string) => {
    try {
      const response = await fetchOrdersByClient(clientId);

      if (isEmpty(response?.items)) {
        toast.error('У этого контакта нет сделок');
      } else {
        setOrders(response?.items);
      }
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async ({ date, ...values }: CalendarEntity) => {
    setIsFetching(true);

    const contact = isString(values?.contact) ? values?.contact : values?.contact?._id;

    const serviceBay = typeof values.serviceBay === "object" ? values?.serviceBay._id : values?.serviceBay;

    const payload = {
      startAt: dayjs(values.startAt, { utc: false }).format(),
      endAt: dayjs(values.endAt, { utc: false }).format(),
      contact,
      serviceBay: serviceBay ?? "",
      type: values.type,
      order: typeof values.order === "object" ? values.order?._id : values.order,
      comment: values.comment,
      marker: values.marker,
    };

    try {
      if (isEdit) {
        await fetchUpdateCalendarEntry(calendarEntity?._id as string, payload);
      } else {
        await fetchCreateCalendarEntry(payload);
      }

      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success(isEdit ? 'Запись в календаре успешно изменена!' : 'Запись в календарь успешно добавлена!');
      onClose?.();
    } catch (err) {
      setIsFetching(false);

      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <Formik
        enableReinitialize
        initialValues={calendarEntity}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>{isEdit ? 'Редактирование записи' : 'Создание записи'}</h2>
            </ModalHeader>
            <ModalBody>
              {isLoading ? (
                <LoadingIndicatorPage />
              ) : (
                <Flex flexDirection="column">
                  <Flex>
                    <CalendarMarkers
                      // @ts-ignore
                      variant={values[FormFields.Marker]}
                      onSelect={(value) => {
                        setFieldValue(FormFields.Marker, value);
                      }}
                    />
                  </Flex>
                  <Grid>
                    <Grid.Item col={12}>
                      <Select
                        label="Пост/Менеджер"
                        defaultValue={{
                          label: (item?.serviceBay as ServiceBay)?.title || serviceBay?.title || 'Выберите из списка',
                          value: (item?.serviceBay as ServiceBay)?._id || serviceBay?._id,
                        }}
                        options={calendarGroup.map((item) => ({
                          label: item?.serviceBay?.title || 'Выберите из списка',
                          value: item?.serviceBay?._id,
                        }))}
                        onChange={(e) => setFieldValue(FormFields.ServiceBay, e?.value)}
                      />
                    </Grid.Item>
                    <Grid.Item col={12}>
                      <p className="font-medium">Время и дата</p>
                    </Grid.Item>
                    <Grid.Item col={3}>
                      <DatePicker
                        placeholder="Дата начала"
                        name={FormFields.StartAt}
                        onChange={(value: Date | null) => {
                          setFieldValue(FormFields.StartAt, value ? value : null);
                        }}
                        // @ts-ignore
                        date={values[FormFields.StartAt]}
                      />
                    </Grid.Item>
                    <Grid.Item col={3}>
                      <Select
                        isDisabled={isEmpty(values.startAt?.toString())}
                        options={getTimeRange(values.startAt)}
                        value={{
                          label: dayjs(values.startAt).format('HH:mm'),
                          value: dayjs(values.startAt).toDate(),
                        }}
                        onChange={(e) => {
                          setFieldValue(FormFields.StartAt, e?.value);
                        }}
                        placeholder="Время начала"
                      />
                    </Grid.Item>
                    <Grid.Item col={3}>
                      <DatePicker
                        placeholder="Дата завершения"
                        name={FormFields.EndAt}
                        onChange={(value: Date | null) => {
                          setFieldValue(FormFields.EndAt, value ? value : null);
                        }}
                        // @ts-ignore
                        date={values[FormFields.EndAt]}
                      />
                    </Grid.Item>
                    <Grid.Item col={3}>
                      <Select
                        isDisabled={isEmpty(values.endAt?.toString())}
                        options={getTimeRange(values.endAt)}
                        onChange={(e) => {
                          setFieldValue(FormFields.EndAt, e?.value);
                        }}
                        placeholder="Время завершения"
                        value={{
                          label: dayjs(values.endAt).format('HH:mm'),
                          value: dayjs(values.endAt).toDate(),
                        }}
                      />
                    </Grid.Item>
                  </Grid>

                  <Tabs
                    className={styles.wrapperTabs}
                    onSelect={(value) => {
                      setFieldValue(FormFields.Type, value);
                    }}
                    // @ts-ignore
                    value={values[FormFields.Type]}
                  >
                    {calendarTabs.map(({ label, value }) => (
                      <Tabs.Item key={value} value={value}>
                        {label}
                      </Tabs.Item>
                    ))}
                  </Tabs>

                  {values.type === CalendarEntryTypeEnum.Blocking && <></>}
                  {values.type === CalendarEntryTypeEnum.Order && (
                    <>
                      <Grid>
                        <Grid.Item col={12}>
                          <p className="font-medium">Запись клиента с привязкой к сделке</p>
                        </Grid.Item>
                        <Grid.Item col={6}>
                          <Select
                            name={FormFields.Contact}
                            label="Контакт"
                            options={carServiceBay.filter(item=>item.counterpartProfile).map((item) => ({
                              label: getAgentFullName(item?.counterpartProfile),
                              value: item._id,
                            }))}
                            onChange={(e) => {
                              setFieldValue(FormFields.Contact, e?.value);
                              handleChangeClient(e?.value as string);
                            }}
                            defaultValue={{
                              label:
                                getAgentFullName((item?.contact as UserType)?.counterpartProfile) ||
                                'Выберите из списка',
                              value: (item?.contact as UserType)?._id,
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item col={6}>
                          <Select
                            isDisabled={isEmpty(orders)}
                            name={FormFields.Order}
                            label="Сделка контакта"
                            options={orders.map((item) => ({
                              label: `#${item.increment} от ${getBaseDate(item.createdAt)}`,
                              value: item._id,
                            }))}
                            onChange={(e) => {
                              setFieldValue(FormFields.Order, e?.value);
                            }}
                            defaultValue={{
                              label: (item?.order as OrderEntity)?.increment
                                ? `#${(item?.order as OrderEntity)?.increment} от ${getBaseDate((item?.order as OrderEntity)?.createdAt)}`
                                : 'Выберите из списка',
                              value: (item?.order as OrderEntity)?._id,
                            }}
                          />
                        </Grid.Item>
                      </Grid>

                      <Textarea
                        name={FormFields.Comment}
                        onChange={handleChange}
                        maxLength={300}
                        label="Комментарий"
                        placeholder="Оставьте комментарий"
                        value={values?.comment}
                      />
                    </>
                  )}
                  {values.type === CalendarEntryTypeEnum.Reserve && <></>}
                </Flex>
              )}
            </ModalBody>
            <If condition={!isLoading}>
              <ModalFooter>
                <Flex alignItems="center">
                  <Button disabled={isFetching} type="submit">
                    Сохранить
                  </Button>
                  <Button disabled={isFetching} onClick={onClose} variant="secondary">
                    Отмена
                  </Button>
                </Flex>
              </ModalFooter>
            </If>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
