import { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import type { Dayjs } from 'dayjs';
import { capitalize } from 'lodash';
import { Tooltip, Whisper } from 'rsuite';

import styles from './styles.module.scss';
import type { CalendarEntryItemProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconCalendar from '@/components/Icons/IconCalendar';
import IconCall from '@/components/Icons/IconCall';
import IconEdit from '@/components/Icons/IconEdit';
import IconUser from '@/components/Icons/IconUser';
import Flex from '@/components/UI/Flex';
import { useCalendarContext } from '@/features/Calendar';
import type { OrderEntity } from '@/features/Order/types';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import dayjs from '@/utils/dayjs';
import { Rnd } from 'react-rnd';

export const CalendarEntryItem = ({
  index,
  item,
  colIndex,
  rowIndex,
  parentSize,
  onDragStop,
  onResizing,
}: CalendarEntryItemProps) => {
  const { setShowCalendarEntityModal } = useCalendarContext();
  const blockRef = useRef<Rnd>(null);
  const isDragging = useRef(false); //useRef для того, чтобы не было перересовки
  const isResizing = useRef(false);
  const startPositionForDnd = useRef({ x: 0, y: 0 }); //позиция относительная

  const dateRange = useMemo(() => {
    if (!item) return null;

    return {
      startAt: dayjs(item.startAt),
      endAt: dayjs(item.endAt),
    };
  }, [item]);

  if (!item) {
    return null;
  }

  const { startAt, endAt } = dateRange as { startAt: Dayjs; endAt: Dayjs };

  return (
    <>
      <Rnd
        ref={blockRef}
        className={cn(styles.calendarEntry, styles[`calendarEntry${capitalize(item.marker)}`])}
        role="button"
        // onClick={() => {
        //   setShowCalendarEntityModal!({ show: true, item });
        // }}
        style={{
          top: `${Number(index) * 20}px`,
          // width: `${100 * Number(item?.count)}%`,
          zIndex: 111 - Number(index),
        }}
        size={{ width: Number(item.count) * parentSize.width, height: 60 }}
        enableResizing={{ left: true, right: true, top: false, bottom: false }}
        onDragStart={(e) => {
          isDragging.current = true;
        }}
        onDragStop={(e, d) => {
          const currentHeight = blockRef.current?.resizableElement.current?.offsetHeight;
          //если не двигался курсор, то это просто клик
          if (
            (d.x === 0 && d.y === 0) ||
            (d.x === startPositionForDnd.current.x && d.y === startPositionForDnd.current.y)
          ) {
            isDragging.current = false;
            return;
          }

          onDragStop(
            { item, itemIndex: colIndex },
            {
              colIndex: colIndex + Math.round(d.x / parentSize.width),
              rowIndex: rowIndex + Math.floor(d.y / parentSize.height),
            },
          );
        }}
        onResizeStart={() => (isResizing.current = true)}
        onResizeStop={(e, direction, ref, delta, position) => {
          isResizing.current = false;
          const newWidthInBlock = ref.offsetWidth / parentSize.width;
          // const deltaWidth = ref.offsetWidth - parentSize.width;
          // const rate = Math.round((deltaWidth / parentSize.width - 1) * 100) / 100; //для точности 2 знака после запятой
          if (direction === 'left' || direction === 'right') {
            onResizing(item, direction, newWidthInBlock );
          }
        }}
        onMouseDown={() => {
          isDragging.current = false;
          isResizing.current = false;
        }}
        onClick={() => {
          if (!isDragging.current && !isResizing.current) {
            setShowCalendarEntityModal!({ show: true, item });
          }
        }}
      >
        <Flex className={styles.calendarEntryInner} flexDirection="column" rowGap="xs">
          <DateRange startAt={startAt} endAt={endAt} />
          <If condition={Boolean((item?.order as OrderEntity)?.client)}>
            <Flex alignItems="center" columnGap="xxs">
              <div className={styles.flexHelper}>
                <IconUser size={14} />
                {getAgentFullName((item?.order as OrderEntity)?.client?.counterpartProfile!)}
              </div>
            </Flex>
            <Flex alignItems="center" columnGap="xxs">
              <div className={styles.flexHelper}>
                <IconCall size={14} />
                {(item?.order as OrderEntity)?.client?.counterpartProfile?.phone}
              </div>
            </Flex>
            <If condition={Boolean(item?.comment?.length)}>
              <Flex alignItems="center" columnGap="xxs">
                <div className={styles.flexHelper}>
                  <IconEdit size={14} />
                  {item?.comment}
                </div>
              </Flex>
            </If>
          </If>
        </Flex>
      </Rnd>
    </>
  );
};

const DateRange = ({ startAt, endAt }: { startAt: Dayjs; endAt: Dayjs }) => {
  const date = useMemo(() => `${startAt.format('DD.MM HH:mm [ - ]')} ${endAt.format('DD.MM HH:mm')}`, [startAt, endAt]);

  return (
    <Flex alignItems="center" columnGap="xxs">
      <Whisper controlId={`${startAt}${endAt}`} placement="left" trigger="hover" speaker={<Tooltip>{date}</Tooltip>}>
        <div className={styles.flexHelper}>
          <IconCalendar size={16} />
          {date}
        </div>
      </Whisper>
    </Flex>
  );
};
