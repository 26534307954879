import { useEffect, useMemo, useState } from 'react';

import type { QUnitType } from 'dayjs';
import { isEmpty } from 'lodash';

import { CalendarRange, getRangeValues, getShiftDates } from '../CalendarRangeCells/constants';
import { CalendarRanges } from '../CalendarRanges';

import { If } from '@/components/ConditionalRendering/If';
import Header from '@/components/Header';
import IconAdd from '@/components/Icons/IconAdd';
import LoadingIndicatorPage from '@/components/LoadingIndicatorPage';
import Button from '@/components/UI/Button';
import { EVENTS } from '@/constants';
import { CalendarFormModal, CalendarPostModal, useCalendarContext } from '@/features/Calendar';
import { CalendarTable } from '@/features/Calendar/components/CalendarTable';
import { useEventListener } from '@/hooks';
import dayjs from '@/utils/dayjs';
import { fetchUpdateCalendarEntry } from '../../services';
import { ResizeCalendarType, ShiftCalendarType } from '../CalendarTable/types';

const DAY_IN_HOUR = 1 / 24;
const HOUR_IN_15MIN = 1 / 4;

export const CalendarView = () => {
  const [showPostModal, setShowPostModal] = useState(false);

  const {
    data = [],
    from,
    to,
    isFetching,
    refetch,
    range,
    setRange,
    showCalendarEntityModal,
    setShowCalendarEntityModal,
  } = useCalendarContext();

  const ranges = getRangeValues(range!, dayjs(from), dayjs(to));
  useEventListener(EVENTS.REFRESH_DATA, () => refetch?.());

  const calendar = useMemo(
    () =>
      data.map((calendarItem) => {
        const entries = ranges.values.map((rangeValue) => {
          const items = calendarItem.entries.filter((entryItem, index) => {
            let startAt = dayjs(entryItem.startAt);

            startAt = range === CalendarRange.Day ? startAt.startOf('hour') : startAt.startOf('day');

            const result = rangeValue.isSameOrAfter(startAt) && ranges.endDate?.isSameOrBefore(dayjs(to).endOf('day'));

            if (result) {
              calendarItem.entries.splice(index, 1);
            }

            return result;
          });

          const date = rangeValue;

          if (!isEmpty(items)) {
            let unit = 'day';

            if (range === CalendarRange.Day) {
              unit = 'hour';
            }

            return items.map((item: any) => ({
              ...item,
              date,
              // hasItem: true, // Не понятно зачем это вообще
              count: Math.ceil(dayjs(item.endAt, { utc: false }).diff(rangeValue, unit as QUnitType, true)),
            }));
          }

          return {
            date,
            // hasItem: false, // Не понятно зачем это вообще
          };
        });

        return {
          ...calendarItem,
          entries,
        };
      }),
    [data],
  );

  // const handleChangeCalendar = async (from, changeType, to ) => {
  //   //вынуждены получать объект итема снизу, так как data не имеет нужных полей на момент дропа
  //   const item = from.item;

  //   let shiftRange: 'hour' | CalendarRange = 'hour';
  //   if (range === CalendarRange.Month || range === CalendarRange.Week) {
  //     shiftRange = CalendarRange.Day;
  //   }

  //   await fetchUpdateCalendarEntry(_data.from.item._id, payload);
  //   //временное решение
  //   refetch?.();
  // };

  const handleShiftCalendar = async (data: ShiftCalendarType) => {
    const { from, to } = data;
    //вынуждены получать объект итема снизу, так как data не имеет нужных полей на момент дропа
    const { item } = from;

    let shiftRange: 'hour' | CalendarRange = 'hour';
    if (range === CalendarRange.Month || range === CalendarRange.Week) {
      shiftRange = CalendarRange.Day;
    }

    //Нужно руками считать сдвиг на сколько клеток ушло и вычитать из даты опреденное количество, в зависимости от значения range
    const payload = {
      endAt: dayjs(getShiftDates(shiftRange, item.endAt, to.itemIndex - from.itemIndex)).toISOString(),
      startAt: dayjs(getShiftDates(shiftRange, item.startAt, to.itemIndex - from.itemIndex)).toISOString(),
      contact: typeof item?.contact === 'object' ? item.contact._id : item.contact,
      serviceBay: to.serviceBayId ?? (typeof item.serviceBay === 'object' ? item.serviceBay._id : item.serviceBay),
      type: item.type,
      order: typeof item.order === 'object' ? item.order?._id : item.order,
      comment: item.comment,
      marker: item.marker,
    };
    if (item._id) {
      await fetchUpdateCalendarEntry(item._id, payload);
      //временное решение
      refetch?.();
    }
  };
  const handleResizeCalendar = async (data: ResizeCalendarType) => {
    const { from, to } = data;
    //вынуждены получать объект итема снизу, так как data не имеет нужных полей на момент ресайза
    const { item } = from;

    let shiftRange: CalendarRange | 'minute' | 'hour' = 'hour';
    let checkPoint = HOUR_IN_15MIN;
    if (range === CalendarRange.Month || range === CalendarRange.Week) {
      shiftRange = CalendarRange.Day;
      checkPoint = DAY_IN_HOUR;
    }

    const newWidthByBlock = Math.round(to.newWidthByBlock / checkPoint) * checkPoint;
    const shift = newWidthByBlock;

    let startAt = item.startAt;
    let endAt = item.endAt;

    if (to.direction === 'left') {
      startAt = dayjs(getShiftDates(shiftRange, dayjs(item.endAt).endOf(shiftRange), -shift)).toISOString();
      // const startPositionOfStartAt =
      //   dayjs(item.endAt).startOf(shiftRange).toDate().getTime() === new Date(item.endAt).getTime()
      //     ? item.endAt
      //     : dayjs(item.endAt).endOf(shiftRange);
      // startAt = dayjs(getShiftDates(shiftRange, startPositionOfStartAt, -shift)).toISOString();
    }
    if (to.direction === 'right') {
      endAt = dayjs(getShiftDates(shiftRange, dayjs(item.startAt).startOf(shiftRange), shift)).toISOString();
    }
    const payload = {
      endAt,
      startAt,
      contact: typeof item?.contact === 'object' ? item.contact._id : item.contact,
      serviceBay: typeof item.serviceBay === 'object' ? (item.serviceBay._id ?? '') : item.serviceBay,
      type: item.type,
      order: typeof item.order === 'object' ? item.order?._id : item.order,
      comment: item.comment,
      marker: item.marker,
    };
    if (item._id) {
      await fetchUpdateCalendarEntry(item._id, payload);
      //временное решение
      refetch?.();
    }
  };

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <>
      <Header
        actions={
          <>
            <Button endIcon={IconAdd} onClick={() => setShowCalendarEntityModal!({ show: true })}>
              Запись
            </Button>
            <Button endIcon={IconAdd} onClick={() => setShowPostModal(true)} variant="secondary">
              Пост
            </Button>
          </>
        }
        title="Календарь"
        showSearch={false}
      >
        <CalendarRanges onSelect={setRange!} range={range!} />
      </Header>

      {/* <CalendarTable data={calendar} range={range!} handleChangeCalendar={handleChangeCalendar} /> */}
      <CalendarTable
        data={calendar}
        range={range!}
        handleShiftCalendar={handleShiftCalendar}
        handleResizeCalendar={handleResizeCalendar}
      />

      <If condition={showCalendarEntityModal!.show}>
        <CalendarFormModal
          item={showCalendarEntityModal!.item}
          isOpen={showCalendarEntityModal!.show}
          date={showCalendarEntityModal!.date}
          serviceBay={showCalendarEntityModal!.serviceBay}
          onClose={() => setShowCalendarEntityModal!({ show: false })}
          calendarGroup={data as any} // временно
        />
      </If>

      <If condition={showPostModal}>
        <CalendarPostModal isOpen={showPostModal} onClose={() => setShowPostModal(false)} />
      </If>
    </>
  );
};
