import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { isArray, map } from 'lodash';

import { CalendarEntryItem } from '../CalendarEntryItem';

import styles from './styles.module.scss';
import type { CalendarTableProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconEdit from '@/components/Icons/IconEdit';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import { CalendarPostModal, useCalendarContext } from '@/features/Calendar';
import { CalendarRangeCells } from '@/features/Calendar/components/CalendarRangeCells';
import { CalendarRange } from '@/features/Calendar/components/CalendarRangeCells/constants';
import type { ServiceBay } from '@/features/Order/types';
import { Direction } from '../CalendarEntryItem/types';
import { CalendarEntity } from '../../types';

export const CalendarTable = ({
  data,
  range,
  handleResizeCalendar,
  handleShiftCalendar,
}: CalendarTableProps) => {
  const [post, setPost] = useState<ServiceBay | null>(null);
  const [showPostModal, setShowPostModal] = useState(false);
  const [parentSize, setParentSize] = useState({ width: 0, height: 0 });
  const parentRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if (parentRef.current) {
      const updateSize = () => {
        const { width, height } = parentRef.current!.getBoundingClientRect();
        setParentSize({ width, height });
      };

      updateSize(); // Вызываем сразу при монтировании
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  const { setShowCalendarEntityModal, from, to } = useCalendarContext();
  console.log('data', data);
  const handleEditPost = (value: ServiceBay) => {
    setPost(value);
    setShowPostModal(true);
  };

  const handleResize = (item: any, direction: Direction, newWidthByBlock: number) => {
    handleResizeCalendar({from:{item},to: {direction, newWidthByBlock}})
  };

  const handleDrop = (
    from: { item: CalendarEntity; itemIndex: number },
    to: { colIndex: number; rowIndex: number },
  ) => {
    let newServiceBayIndex = Math.max(0, to.rowIndex);
    const newServiceBayId = data[newServiceBayIndex].serviceBay._id ?? data[newServiceBayIndex].serviceBay;
    
    handleShiftCalendar({
      from,
      to: {
        itemIndex: to.colIndex,
        serviceBayId: newServiceBayId,
      },
    });
  };

  return (
    <>
      <Flex className={styles.container} rowGap="zero" flexDirection="column">
        <CalendarRangeCells from={from as string} to={to as string} range={range} />
        {map(data, (item, index) => (
          <Flex key={index} className={styles.row} columnGap="zero" rowGap="zero">
            <Flex
              alignItems="center"
              rowGap="zero"
              justifyContent="space-between"
              className={cn(styles.td, range === CalendarRange.Month ? styles.tdMonth : styles.tdFirst)}
            >
              <span>{item?.serviceBay?.title}</span>
              <If condition={range !== CalendarRange.Month}>
                <IconButton
                  icon={IconEdit}
                  onClick={() => handleEditPost(item?.serviceBay)}
                  variant="empty-dark"
                  size="sm"
                />
              </If>
            </Flex>
            {map(item?.entries, (entry, itemIndex) => (
              <div
                key={`entry-${index}-${itemIndex}`}
                className={styles.td}
                onDragOver={(e) => e.preventDefault()}
                ref={parentRef}
              >
                <If condition={isArray(entry)}>
                  {map(entry, (entryItem, entryIndex) => (
                    <>
                    <CalendarEntryItem
                      key={`entry-${index}-${itemIndex}-${entryItem._id}`}
                      index={entryIndex}
                      item={entryItem}
                      colIndex={itemIndex}
                      rowIndex={index}
                      parentSize={parentSize}
                      onDragStop={handleDrop}
                      onResizing={handleResize}
                      />
                      </>
                  ))}
                </If>

                <button
                  className={styles.tdCreateBtn}
                  onClick={() =>
                    setShowCalendarEntityModal!({
                      date: entry.date,
                      show: true,
                      serviceBay: item?.serviceBay,
                    })
                  }
                />
              </div>
            ))}
          </Flex>
        ))}
      </Flex>

      <If condition={showPostModal}>
        <CalendarPostModal post={post} isOpen={showPostModal} onClose={() => setShowPostModal(false)} />
      </If>
    </>
  );
};
